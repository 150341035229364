/* eslint-env browser, node */

// TODO: use an environment variable to know where we are so webpack is able to remove the unused path.
// Este componente esta siendo migrado al nuevo repositorio de https://github.com/melisource/fury_vpp-frontend-components, no modificar, ante cualquier duda mandar mensaje a Mcalderon

if (typeof window === 'object') {
  module.exports = window;
} else {
  module.exports = global;
}

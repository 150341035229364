const MSG_LOADED = 'vpp:iframe-modal:loaded';
const MSG_LOADING = 'vpp:iframe-modal:loading';
const MSG_CLOSE_BTN = 'vpp:iframe-modal:close_button';
const MSG_REDIRECT = 'vpp:iframe-modal:redirect';
const MSG_REDIRECT_MODAL = 'vpp:iframe-modal:redirect_modal';
const MSG_NAVIGATE = 'vpp:iframe-modal:navigate';
const MSG_CLOSE = 'vpp:iframe-modal:close';
const MSG_SNACKBAR = 'vpp:iframe-modal:snackbar';
const MSG_ASK_SELLER = 'vpp:iframe-modal:ask-seller';

const eventTypes = {
  MSG_LOADED,
  MSG_LOADING,
  MSG_CLOSE_BTN,
  MSG_REDIRECT,
  MSG_REDIRECT_MODAL,
  MSG_NAVIGATE,
  MSG_CLOSE,
  MSG_SNACKBAR,
  MSG_ASK_SELLER,
};

// TODO: (todo-1pay4all) propuesta mientras se define el track con backend
const KEY_TRACKS = {
  INITIAL_LOAD: 'initial_load',
  WEBVIEW_EVENT: 'webview_event',
};

module.exports = {
  eventTypes,
  KEY_TRACKS,
};

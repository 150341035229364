const React = require('react');
const { useState, useEffect, useRef } = require('react');
const { bool, string, func, shape, number } = require('prop-types');
const { Modal } = require('@andes/modal');
const classNames = require('classnames');
const onIframeModalResize = require('../../lib/on-iframe-modal-resize');
const object = require('../../lib/global');
const { trackEvent } = require('../../lib/tracking');
const Iframe = require('../iframe');
const targetHostnameValidator = require('../../utils/targetHostnameValidator');
const { eventTypes } = require('./utils/constants');
const { VPP_SEQUENCER_DATADOG_KEY_CASES } = require('../../services/frontend-statsd/config/allowed-keys');
const { REASON_TAG_VALUES } = require('../../services/frontend-statsd/config/allowed-tags');
const { updateOnePayForAllTrack, ACTIONS_SEQUENCER_TRACK } = require('../../actions/utils/onePayForAllUtils');

const {
  MSG_LOADED,
  MSG_LOADING,
  MSG_CLOSE_BTN,
  MSG_REDIRECT,
  MSG_REDIRECT_MODAL,
  MSG_NAVIGATE,
  MSG_CLOSE,
  MSG_SNACKBAR,
  MSG_ASK_SELLER,
} = eventTypes;

const DEFAULT_TIMEOUT_INITIAL_LOAD = 10000;
const DEFAULT_TIMEOUT_WEBVIEW_MESSAGE = 5000;

const IFrameModal = ({
  id,
  src,
  title,
  show,
  deviceType,
  fallbackConfig,
  statsdConfig,
  isDismissible,
  showSnackbar,
  bottomSheetClose,
  updateIframeModal,
  updateComponentsBottomSheet,
  onCloseVariations, // TODO-FRAMES Este metodo deberia dejar de existir cuando se apaguen pickers viejos + modal variaciones
  onCreateQuestionFromAi,
  saveFrontendStatsd,
  sequencerTrack,
}) => {
  const iframeFallbackTimeout = useRef(null);
  const iframeRedirectFlow = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const [onCloseTracks, setOnCloseTracks] = useState(null);
  const isQuestionsAiModal = src && src.includes('questions-ai');
  const typeMobile = isQuestionsAiModal ? 'small' : 'card'; // Hacemos esto para no mostrar la X por fuera (esta en nuestra webview de questions)
  const redirectPaymentsModal = src && src.includes('/payments?new_version=true&modal=true&newIndex=true');
  const typeModal = deviceType === 'mobile' ? typeMobile : 'large';
  const { keyPrefix, baseTags } = statsdConfig || {};
  const { fallbackUrl, fallbackMessage, timeoutInitialLoad, timeoutWebviewMessage } = fallbackConfig || {};

  const feStatsdHelper = ({ key, aditionalTags = {}, aditionalInfo, shouldLogMessage }) => {
    if (saveFrontendStatsd && keyPrefix && baseTags) {
      saveFrontendStatsd({
        key: `${keyPrefix}.${key}`,
        tags: {
          ...baseTags,
          ...aditionalTags,
        },
        info: shouldLogMessage && aditionalInfo,
      });
    }
  };

  const showSnackbarAndSendMetric = ({ message, aditionalTags = {}, aditionalInfo, shouldLogMessage }) => {
    feStatsdHelper({
      key: VPP_SEQUENCER_DATADOG_KEY_CASES.SHOW_SNACKBAR,
      aditionalTags: {
        snackbar_type: 'error',
        ...aditionalTags,
      },
      aditionalInfo,
      shouldLogMessage,
    });
    showSnackbar({ message, type: 'error' });
  };

  const goToUrl = ({ url, target, newIsDismissible, newFallbackConfig }) => {
    const validUrl = url && targetHostnameValidator({ target: url, currentLocation: window.location });
    if (validUrl) {
      if (target === 'parent') {
        window.location.href = url;
      }
      if (target === 'modal' && updateIframeModal) {
        setLoaded(false);
        setOnCloseTracks(null); // Cleans the tracks -this is set again during the load event-
        updateIframeModal({
          src: url,
          fallbackConfig: newFallbackConfig,
          isDismissible: newIsDismissible,
        });
        iframeRedirectFlow.current = true;
        feStatsdHelper({
          key: VPP_SEQUENCER_DATADOG_KEY_CASES.WEBVIEW_REDIRECT,
        });
      }
    } else {
      showSnackbarAndSendMetric({
        aditionalTags: {
          reason: REASON_TAG_VALUES.INVALID_REDIRECT_URL,
        },
        aditionalInfo: { url, target },
        shouldLogMessage: true,
      });
    }
  };

  const updateCartIcon = () => {
    object.freya?.emit('cart:refresh');
  };

  const onCloseModal = () => {
    // TODO-FRAMES Este metodo deberia dejar de existir cuando se apaguen pickers viejos + modal variaciones
    if (onCloseVariations) {
      onCloseVariations();
    }
    if (bottomSheetClose) {
      bottomSheetClose();
    }
    if (onCloseTracks) {
      trackEvent(onCloseTracks);
      setOnCloseTracks(null);
    }
    iframeRedirectFlow.current = false;
  };

  const handleLoadedEvent = (data = {}) => {
    const { trackData = null, refreshCartIcon = false, shouldUpdateComponents = false, disableClose = false } = data;
    // Fallback redirect && loaded
    clearTimeout(iframeFallbackTimeout.current);
    setLoaded(true);

    // TODO-FRAMES: BORRAR ESTE IF ## Esto lo dejamos hasta que BE nos mande el isDismissible=false para GRD y mientras tanto no perder la funcionalidad.
    if (disableClose) {
      updateIframeModal({ isDismissible: false });
    }

    // Tracks
    if (trackData) {
      setOnCloseTracks({
        melidata_event: { ...trackData },
      });
    }

    if (sequencerTrack) {
      trackEvent(updateOnePayForAllTrack(sequencerTrack, ACTIONS_SEQUENCER_TRACK.LOADED));
    }

    feStatsdHelper({
      key: VPP_SEQUENCER_DATADOG_KEY_CASES.FLOW_END,
      aditionalTags: {
        end_case: iframeRedirectFlow.current ? 'bottom_sheet_redirect' : 'bottom_sheet',
      },
    });

    // Updates
    if (refreshCartIcon) {
      updateCartIcon();
    }
    if (shouldUpdateComponents && updateComponentsBottomSheet) {
      updateComponentsBottomSheet();
    }
  };

  const handleNavigateEvent = (data = {}) => {
    const { url, fallback, fallback_message, timeout, timeout_webview_message, is_dismissible } = data;
    goToUrl({
      url,
      target: 'modal',
      newFallbackConfig: {
        ...fallbackConfig,
        fallbackUrl: fallback,
        fallbackMessage: fallback_message,
        timeoutInitialLoad: timeout,
        timeoutWebviewMessage: timeout_webview_message,
      },
      newIsDismissible: is_dismissible,
    });
  };

  const handleSnackbarEvent = (data = {}) => {
    const { snackbar, close } = data;
    const { message, type, delay, called_from, action } = snackbar;
    if (close) {
      onCloseModal();
    }
    if (action) {
      action.onClick = () => goToUrl({ url: action.redirectUrl, target: 'parent' });
    }
    showSnackbar({ message, type, delay, called_from, action });
  };

  const handleAskSellerEvent = (data = {}) => {
    onCloseModal();
    onCreateQuestionFromAi({
      itemId: data.itemId,
      text: data.question,
      snackbar_message: data.snackbar_message,
      suggestionTrack: data.track,
    });
  };

  const handleMessage = e => {
    if (e?.data?.type) {
      switch (e.data.type) {
        case MSG_LOADED:
          handleLoadedEvent(e.data.data);
          break;
        case MSG_LOADING:
          setLoaded(false);
          break;
        case MSG_CLOSE_BTN:
          updateIframeModal({ isDismissible: e.data.enabled });
          break;
        case MSG_REDIRECT:
          goToUrl({ url: e.data.redirectUrl, target: 'parent' });
          break;
        case MSG_REDIRECT_MODAL:
          goToUrl({ url: e.data.redirectUrl, target: 'modal' });
          break;
        case MSG_NAVIGATE:
          handleNavigateEvent(e.data);
          break;
        case MSG_CLOSE:
          onCloseModal();
          break;
        case MSG_SNACKBAR:
          if (e.data.snackbar && showSnackbar) {
            handleSnackbarEvent(e.data);
          }
          break;
        case MSG_ASK_SELLER:
          if (e.data.question && e.data.itemId && onCreateQuestionFromAi) {
            handleAskSellerEvent(e.data);
          }
          break;
        default:
          break;
      }
    }
  };

  const handleIframeOnLoad = () => {
    clearTimeout(iframeFallbackTimeout.current);
    if (fallbackConfig) {
      iframeFallbackTimeout.current = setTimeout(() => {
        // To Do: Validar contrato del track
        if (sequencerTrack) {
          trackEvent(updateOnePayForAllTrack(sequencerTrack));
        }
        if (fallbackUrl && targetHostnameValidator({ target: fallbackUrl, currentLocation: window.location })) {
          feStatsdHelper({
            key: VPP_SEQUENCER_DATADOG_KEY_CASES.REDIRECT_TO_FALLBACK,
            aditionalTags: {
              reason: REASON_TAG_VALUES.TIMEOUT_WEBVIEW_MESSAGE,
              flow_redirect: Boolean(iframeRedirectFlow.current),
            },
          });
          window.location.href = fallbackUrl;
        } else {
          showSnackbarAndSendMetric({
            message: fallbackMessage?.message,
            aditionalTags: {
              reason: REASON_TAG_VALUES.TIMEOUT_WEBVIEW_MESSAGE,
              flow_redirect: Boolean(iframeRedirectFlow.current),
            },
          });
          onCloseModal();
        }
      }, timeoutWebviewMessage || DEFAULT_TIMEOUT_WEBVIEW_MESSAGE);
    }
  };

  useEffect(() => {
    if (src && fallbackConfig) {
      iframeFallbackTimeout.current = setTimeout(() => {
        // To Do: Validar contrato del track
        if (sequencerTrack) {
          trackEvent(updateOnePayForAllTrack(sequencerTrack));
        }
        if (fallbackUrl && targetHostnameValidator({ target: fallbackUrl, currentLocation: window.location })) {
          feStatsdHelper({
            key: VPP_SEQUENCER_DATADOG_KEY_CASES.REDIRECT_TO_FALLBACK,
            aditionalTags: {
              reason: REASON_TAG_VALUES.TIMEOUT_INITIAL_LOAD,
              flow_redirect: Boolean(iframeRedirectFlow.current),
            },
          });
          window.location.href = fallbackUrl;
        } else {
          showSnackbarAndSendMetric({
            message: fallbackMessage?.message,
            aditionalTags: {
              reason: REASON_TAG_VALUES.TIMEOUT_INITIAL_LOAD,
              flow_redirect: Boolean(iframeRedirectFlow.current),
            },
          });
          onCloseModal();
        }
      }, timeoutInitialLoad || DEFAULT_TIMEOUT_INITIAL_LOAD);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  useEffect(() => {
    if (!show) {
      setLoaded(false);
    }

    if (onCloseVariations && show) {
      document.querySelector('.ui-pdp-backdrop-modal').setAttribute('style', 'height:0px');
      document.querySelectorAll('.andes-modal__overlay')[0].setAttribute('style', 'height:0px');
    }
    object.addEventListener('message', handleMessage);
    return () => {
      object.removeEventListener('message', handleMessage);
      clearTimeout(iframeFallbackTimeout.current); // Cuando se cierra la BS matamos el timeout actual ( ** Lo hacemos para asegurarnos, aunque ya deberia estar clear)
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const modalClassName = classNames('iframe-modal-congrats', {
    'iframe-modal-congrats--desktop': typeModal === 'large',
    'iframe-modal-congrats--mobile': typeModal !== 'large',
    'iframe-modal-questions-ai': isQuestionsAiModal,
    'iframe-modal-redirect-payments': redirectPaymentsModal,
  });

  return (
    <Modal
      open={show}
      id={id}
      onClose={onCloseModal}
      type={typeModal}
      title={title || ' '}
      className={modalClassName}
      closable={loaded && isDismissible !== false}
    >
      <Iframe
        title="iframe"
        data-testid="iframe-element"
        src={src}
        showSpinner
        scrolling={redirectPaymentsModal ? 'no' : 'auto'}
        forceSpinner={!loaded}
        spinnerClassname="center-spinner"
        onLoad={handleIframeOnLoad}
        onMessage={onIframeModalResize(
          'vpp:iframe-modal:resize',
          'height',
          '.ui-pdp-iframe-modal',
          '.andes-modal__header',
        )}
      />
    </Modal>
  );
};

IFrameModal.propTypes = {
  id: string,
  src: string.isRequired,
  title: string,
  show: bool,
  deviceType: string.isRequired,
  fallbackConfig: shape({
    fallbackUrl: string,
    fallbackMessage: shape({
      message: string,
      description: string,
      owner: string,
      errorValue: string,
    }),
    timeoutInitialLoad: number,
    timeoutWebviewMessage: number,
  }),
  statsdConfig: shape({
    keyPrefix: string,
    baseTags: shape({}),
  }),
  isDismissible: bool,
  bottomSheetClose: func.isRequired,
  updateIframeModal: func.isRequired,
  updateComponentsBottomSheet: func,
  onCloseVariations: func,
  onCreateQuestionFromAi: func,
  showSnackbar: func,
  saveFrontendStatsd: func,
  sequencerTrack: shape({
    melidata_event: shape({}),
  }),
};

IFrameModal.defaultProps = {
  id: '',
  title: '',
  show: false,
  fallbackConfig: null,
  statsdConfig: null,
  isDismissible: true,
  updateComponentsBottomSheet: null,
  onCloseVariations: null,
  onCreateQuestionFromAi: null,
  showSnackbar: null,
  saveFrontendStatsd: null,
};

module.exports = IFrameModal;
